// export const API_URL = "http://localhost:6006"
// export const API_URL = "http://16.170.237.29:6006"
// export const API_URL = "http://35.154.251.152:6006"
export const API_URL = "https://api.mymagictrick.com"
export const transformOfferData = (offerData) => {
    return {
        title: offerData.title,
        description: offerData.description,
        photo: offerData.photo,
        category: offerData.category,
        show_in_hot_deals: offerData.showInHotDeals,
        hot_deals_ranking: offerData.hotDealsRanking,
        show_in_new_deals: offerData.showInNewDeals,
        new_deals_ranking: offerData.newDealsRanking,
        original_price: offerData.originalPrice,
        discount_price: offerData.discountPrice,
        merchant: offerData.merchant,
        product_link: offerData.productLink,
        percentage_off: offerData.percentageOff
    };
};

export const transformCategoryData = (offerData) => {
    return {
        name: offerData.name,
        photo: offerData.photo,
    };
};


export const merchant = [
    { id: 1, name: 'Amazon' },
    { id: 2, name: 'Flipkart' },
    { id: 3, name: 'Myntra' },
    { id: 4, name: 'Ajio' },
];

export const affiliates = [
    { id: 1, name: 'Amazon', affid: 'rajshah069-21' },
    { id: 2, name: 'Flipkart',affid:'rohanpouri&affExtParam1=ENKR20241205A1218679373&affExtParam2=645253' },
    { id: 3, name: 'Myntra',affid:'' },
    { id: 4, name: 'Ajio',affid:'' },
];
