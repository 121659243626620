// src/components/AddCategory.jsx
import React, { useState } from "react";
import {
  CircularProgress,
  Grid,
  TextField,
  Button,
  Typography,
  Alert,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import {
  AddBoxOutlinedIcon,
  CancelOutlinedIcon,
  CategoryOutlinedIcon,
  CloudUploadOutlinedIcon,
  DeleteIcon,
  ContentCutOutlinedIcon,
} from "../../Icons";
import withTimedDisplay from "../../utils/timedAlert";
//import { CircularProgress, Grid, TextField, Button, Typography, Alert, useTheme, Box, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { createCategory } from "../../api/api";
import defaultPreviewImg from "../../assets/Images/default-preview-image.jpg";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);
  const breadcrumbsLinks = [
    {
      id: 2,
      title: "Add Category",
      href: "/category/add",
      icon: <CategoryOutlinedIcon fontSize="small" />,
    },
  ];

  const handleImageChange = (e) => {
    setError(null);

    const file = e.target.files[0];

    if (!file) return;

    const preview = URL.createObjectURL(file); // Generate preview URL for the file

    const img = new Image();
    img.src = preview;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const needsCrop = aspectRatio > 1;

      if (needsCrop) {
        // setCurrentImage(file);
        setError(
          "Image aspect ratio is not supported. Please upload a new image or crop it manually."
        );
        setShowAlert(true);
      } else {
        setError(null);
        setShowAlert(true);
      }
      setPhoto({ file, needsCrop });
      // setPhoto(file);
      setPreviews([preview]);
    };
  };

  const handleImageCrop = (index) => {
    setPhoto((prev) => ({ ...prev, needsCrop: false }));
    setError(null);
  };

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append("name", name);
    if (photo && photo?.file) {
      formData.append("photo", photo.file);
    } else {
      try {
        const response = await fetch(defaultPreviewImg);
        const blob = await response.blob();
        const defaultFile = new File([blob], "default-image.jpg", {
          type: blob.type,
        });
        formData.append("photo", defaultFile);
      } catch (err) {
        setError("Failed to load default image");
        setShowAlert(true);
        setLoading(false);
        return;
      }
    }

    try {
      await createCategory(formData);
      setSuccess("Category created successfully!");
      setName("");
      setPreviews([]);
      setShowAlert(true);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDelete = (index) => {
    const updatedPreviews = previews.filter((_, idx) => idx !== index);
    setPhoto({});
    setPreviews(updatedPreviews);
  };

  return (
    <div>
      <Box m="0rem 2.5rem">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header breadcrumbsLinks={breadcrumbsLinks} />
          <Link to={`/category`}>
            <Button
              startIcon={<AddBoxOutlinedIcon />}
              sx={{
                mx: 1,
                py: 1,
                background: theme.palette.primary[500],
                color: "white",
              }}
              variant="contained"
            >
              List Category
            </Button>
          </Link>
        </Box>

        <div>
          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              {error && showAlert && (
                <TimedAlert setShowAlert={setShowAlert} severity="error">
                  {error}
                </TimedAlert>
              )}
              {success && showAlert && (
                <TimedAlert setShowAlert={setShowAlert} severity="success">
                  {success}
                </TimedAlert>
              )}
            </div>
            <Box>
              <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      type="file"
                      hidden
                      onChange={handleImageChange}
                      // required
                    />
                    <CloudUploadOutlinedIcon />
                  </IconButton>
                  <span className="text-sm">Upload photo</span>
                  {photo && (
                    <Typography variant="body2" ml={2}>
                      {photo.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {previews.length > 0 ? (
                    <Grid container spacing={2}>
                      {previews.map((preview, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <div className="relative">
                            <img
                              src={preview}
                              alt={`Preview ${index + 1}`}
                              style={{
                                width: "50px",
                                aspectRatio: "1 / 1",
                                height: "50px",
                              }}
                            />
                            {photo.needsCrop && (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <IconButton
                                  sx={{
                                    px: 2,
                                    background: "#2563EB",
                                    "&:hover": {
                                      background: "#2563EB",
                                    },
                                    border: "1px solid white",
                                    borderRadius: "999px",
                                  }}
                                  onClick={() => handleImageCrop(index)}
                                >
                                  <span>
                                    <ContentCutOutlinedIcon
                                      sx={{ fontSize: "18px", color: "white" }}
                                    />
                                  </span>
                                </IconButton>
                              </div>
                            )}
                            <div>
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                  color: "white",
                                  borderRadius: "50%",
                                }}
                                onClick={() => handleImageDelete(index)}
                              >
                                <DeleteIcon fontSize="medium" />
                              </IconButton>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <img
                      className="w-24"
                      src={defaultPreviewImg}
                      alt="Your Company"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      startIcon={<AddBoxOutlinedIcon />}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        py: 1,
                        mt: 2,
                        mr: 2,
                        background: theme.palette.primary[500],
                        color: "white",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Add Category"
                      )}
                    </Button>

                    <Button
                      // onClick={handleClear}
                      startIcon={<CancelOutlinedIcon />}
                      variant="contained"
                      color="primary"
                      sx={{
                        py: 1,
                        mt: 2,
                        background: theme.palette.primary[800],
                        color: "white",
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </div>
      </Box>
    </div>
  );
};

export default AddCategory;
