import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  useTheme,
  Alert,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useParams, Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  AddBoxOutlinedIcon,
  CancelOutlinedIcon,
  CategoryOutlinedIcon,
  CloudUploadOutlinedIcon,
  ListOutlinedIcon,
} from "../../Icons";
import { editCategoryById, getCategoryById } from "../../api/api";
import { transformCategoryData } from "../../constant";
import withTimedDisplay from "../../utils/timedAlert";

const EditCategory = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    photo: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await getCategoryById(id);
        const categoryData = response.data;
        const transformedData = transformCategoryData(categoryData);
        setFormData(transformedData);
      } catch (err) {
        handleError(err);
        setShowAlert(true);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      if (formData.photo) {
        formDataToSend.append("photo", formData.photo);
      } else {
        setError("Photo is required");
        setShowAlert(true);

        return;
      }

      await editCategoryById(id, formDataToSend);
      setSuccess("Category Updated Successfully");
      setShowAlert(true);
      setFormData({ name: "", photo: null });
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setFormData({
      name: "",
      photo: null,
    });
    setSuccess(null);
    setError(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
  };

  const breadcrumbsLinks = [
    {
      id: 1,
      title: "Edit Category",
      href: `/category/${id}`,
      icon: <CategoryOutlinedIcon fontSize="small" />,
    },
  ];

  return (
    <div>
      <Box m="0rem 2.5rem">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header breadcrumbsLinks={breadcrumbsLinks} />
          <Link to={`/category`}>
            <Button
              startIcon={<ListOutlinedIcon />}
              sx={{
                mx: 1,
                py: 1,
                background: theme.palette.primary[500],
                color: "white",
              }}
              variant="contained"
            >
              List Categories
            </Button>
          </Link>
        </Box>

        <form onSubmit={handleSubmit}>
          {error && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="error">
              {error}
            </TimedAlert>
          )}
          {success && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="success">
              {success}
            </TimedAlert>
          )}
          <Box>
            <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input type="file" hidden onChange={handleFileChange} />
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <Typography variant="body2" ml={2}>
                  {formData.photo
                    ? formData?.photo?.name
                      ? formData.photo.name
                      : formData.photo
                    : "No file selected"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    startIcon={<AddBoxOutlinedIcon />}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      py: 1,
                      mt: 2,
                      mr: 2,
                      background: theme.palette.primary[500],
                      color: "white",
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Update Category"
                    )}
                  </Button>

                  <Button
                    onClick={handleClear}
                    startIcon={<CancelOutlinedIcon />}
                    variant="contained"
                    color="primary"
                    sx={{
                      py: 1,
                      mt: 2,
                      background: theme.palette.primary[800],
                      color: "white",
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default EditCategory;
