import React, { useState, useEffect } from "react";

const withTimedDisplay = (Component, timeout = 5000) => {
  return React.memo(function TimedComponent(props) {
    const [isVisible, setIsVisible] = useState(true);
    console.log("running function here isvisible", isVisible);
    useEffect(() => {
      const timer = setTimeout(() => {
        props.setShowAlert(false);
        setIsVisible(false);
      }, timeout);

      return () => clearTimeout(timer);
    }, [timeout]);

    return isVisible ? <Component {...props} /> : null;
  });
};

export default withTimedDisplay;
