import React, { useEffect, useState } from "react";
import {
  deleteCategoryById,
  deleteProductById,
  getAllCategory,
} from "../../api/api";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import {
  AddBoxOutlinedIcon,
  CategoryOutlinedIcon,
  CloseIcon,
  DeleteOutlineOutlinedIcon,
  EditOutlinedIcon,
  LocalOfferOutlinedIcon,
  SearchOutlinedIcon,
  ViewCompactAltOutlinedIcon,
} from "../../Icons";
import OffersDetails from "../../components/Dialogs/OffersDetails";
import CategoryDetails from "../../components/Dialogs/CategoryDetails";
import DeleteOffers from "../../components/Dialogs/DeleteOffers";
import { API_URL } from "../../constant";
import withTimedDisplay from "../../utils/timedAlert";

const ListCategories = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [category, setCategory] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [success, setSuccess] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
      } else {
        setError("An unexpected error occurred.");
      }
    } else {
      setError("An unexpected error occurred.");
    }
  };

  const fetchOffers = async (pageNumber = null, pageSize = null) => {
    setLoading(true);
    try {
      const currentPage =
        pageNumber !== null ? pageNumber : paginationModel.page + 1;
      const currentPageSize =
        pageSize !== null ? pageSize : paginationModel.pageSize;

      const response = await getAllCategory(
        true,
        currentPage,
        currentPageSize,
        sortOrder,
        searchQuery
      );

      const totalPages = Math.ceil(response.data.totalCount / currentPageSize);

      if (currentPage > totalPages && totalPages > 0) {
        const newPageNumber = totalPages - 1;
        setPaginationModel((prevModel) => ({
          ...prevModel,
          page: newPageNumber,
        }));
        return fetchOffers(newPageNumber + 1, currentPageSize);
      }

      setCategory(response.data.category);
      setTotalCount(response.data.totalCount);

      // Update paginationModel state if the current page was adjusted
      if (pageNumber !== null) {
        setPaginationModel((prevModel) => ({
          ...prevModel,
          page: pageNumber - 1,
        }));
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [paginationModel, sortOrder, searchQuery]);

  const breadcrumbsLinks = [
    {
      id: 2,
      title: "Category",
      href: "/category",
      icon: <CategoryOutlinedIcon fontSize="small" />,
    },
  ];

  const handleViewClick = (cat) => {
    setSelectedCategory(cat);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCategory(null);
  };

  const handleDeleteClick = (cat) => {
    setOfferToDelete(cat);
    setError(null);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setOfferToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (offerToDelete) {
      try {
        const response = await deleteCategoryById(offerToDelete.id);
        fetchOffers();
        setSuccess(response.data.message);
        setShowAlert(true);
        setDeleteDialogOpen(false);
        setOfferToDelete(null);
      } catch (err) {
        handleError(err);
        setShowAlert(true);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box m="0rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header breadcrumbsLinks={breadcrumbsLinks} />
        <Link to={`/category/add`}>
          <Button
            startIcon={<AddBoxOutlinedIcon />}
            sx={{
              mx: 1,
              py: 1,
              background: theme.palette.primary[500],
              color: "white",
            }}
            variant="contained"
          >
            Add Category
          </Button>
        </Link>
      </Box>

      <div className="flex justify-end">
        <TextField
          label="Search Offer"
          name="search"
          value={searchQuery}
          onChange={handleSearchChange}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ color: theme.palette.background.deepSea }}
              >
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <CategoryDetails
        open={dialogOpen}
        onClose={handleCloseDialog}
        offer={selectedCategory}
      />

      <DeleteOffers
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        title={"Category"}
        error={error}
      />

      <Box sx={{ height: "auto", mt: 2 }}>
        {success && showAlert && (
          <TimedAlert
            severity="success"
            setShowAlert={setShowAlert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setSuccess(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {success}
          </TimedAlert>
        )}

        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {category?.length === 0 && showAlert ? (
              <div className="mt-2">
                <TimedAlert setShowAlert={setShowAlert} severity="error">
                  No Category Found
                </TimedAlert>
              </div>
            ) : (
              <TableContainer>
                <Table sx={{ border: "1px solid #E0E0E0", mt: 2 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold", width: "150px" }}>
                        Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "170px" }}>
                        Photo
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "170px" }}>
                        Created At
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "170px" }}>
                        Updated At
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", width: "280px" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {category?.map((cat, index) => (
                      <TableRow key={cat.id}>
                        <TableCell>{cat.name}</TableCell>
                        <TableCell>
                          {cat.photo ? (
                            <img
                              src={`${API_URL}/${cat.photo}`}
                              alt={cat.name}
                              style={{ width: "50px", height: "auto" }}
                            />
                          ) : (
                            "No Photo"
                          )}
                        </TableCell>
                        <TableCell>{cat.createdAt}</TableCell>
                        <TableCell>{cat.updatedAt}</TableCell>
                        <TableCell>
                          <Box display="flex" gap="4px" justifyContent="start">
                            <Link to={`/category/${cat.id}`}>
                              <Button
                                sx={{
                                  px: 2,
                                  background: theme.palette.primary[500],
                                  color: "white",
                                }}
                                startIcon={<EditOutlinedIcon />}
                              >
                                Edit
                              </Button>
                            </Link>
                            <Button
                              onClick={() => handleDeleteClick(cat)}
                              sx={{
                                px: 2,
                                background: theme.palette.primary[500],
                                color: "white",
                              }}
                              startIcon={<DeleteOutlineOutlinedIcon />}
                            >
                              Delete
                            </Button>
                            <Button
                              onClick={() => handleViewClick(cat)}
                              sx={{
                                px: 2,
                                background: theme.palette.primary[500],
                                color: "white",
                              }}
                              startIcon={<ViewCompactAltOutlinedIcon />}
                            >
                              View
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[2, 5, 10]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={paginationModel.pageSize}
                  page={paginationModel.page}
                  onPageChange={(event, newPage) => {
                    setPaginationModel({ ...paginationModel, page: newPage });
                  }}
                  onRowsPerPageChange={(event) => {
                    setPaginationModel({
                      page: 0,
                      pageSize: parseInt(event.target.value, 10),
                    });
                  }}
                />
              </TableContainer>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ListCategories;
