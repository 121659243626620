/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../constant";
import { useNavigate } from "react-router";
import {
  Alert,
  Button,
  CircularProgress,
  useTheme,
  TextField,
} from "@mui/material";
import Heading from "../common/Heading/Heading";
import logo from "../../assets/Images/Logo.png";
import { useAuth } from "../../context/AuthContext";
import ForgotPassword from "../Dialogs/ForgotPassword";
import { Link } from "react-router-dom";
import withTimedDisplay from "../../utils/timedAlert";

const Login = () => {
  const [email, setEmail] = useState("");
  const { login } = useAuth();
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true); // Start loading before the API call

    try {
      await axios.post(`${API_URL}/api/auth/login`, { email, password });
      login();
      navigate("/home");
    } catch (err) {
      if (err.response && err.response.data) {
        if (
          err.response.data.display_errors &&
          err.response.data.display_errors.length > 0
        ) {
          setError(err.response.data.display_errors[0]);
          setShowAlert(true);
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } finally {
      setLoading(false); // Stop loading after the API call
    }
  };

  return (
    <div>
      <div className="flex h-screen flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto mb-4 h-32 w-auto"
            src={logo}
            alt="Your Company"
          />
          <Heading title={"Admin Panel"} />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          <form className="space-y-6" onSubmit={handleLogin}>
            {error && showAlert && (
              <TimedAlert setShowAlert={setShowAlert} severity="error">
                {error}
              </TimedAlert>
            )}
            <div>
              <div className="text-left">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
              </div>
              <TextField
                id="email"
                // label="Email address"
                type="email"
                autoComplete="email"
                placeholder="Enter email address"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                margin="normal"
              />
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    onClick={() => setDrawerOpen(true)}
                    className="cursor-pointer font-semibold text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <TextField
                id="password"
                // label="Password"
                type="password"
                placeholder="Enter your password"
                autoComplete="current-password"
                required
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
              />
            </div>

            <div>
              <Button
                type="submit"
                sx={{
                  width: "100%",
                  background: theme.palette.primary.main,
                  color: "white",
                }}
                variant="contained"
                disabled={loading} // Disable button while loading
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              By logging in, you agree to our{" "}
              <Link
                to="/terms-and-conditions"
                className="text-blue-600 hover:underline"
              >
                Terms and Conditions
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <ForgotPassword open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </div>
  );
};

export default Login;
