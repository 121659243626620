import React, { useEffect, useState } from "react";
import {
  BrokenImageOutlinedIcon,
  CloseIcon,
  DeleteOutlineOutlinedIcon,
  EditOutlinedIcon,
  ViewCompactAltOutlinedIcon,
} from "../../Icons";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { deletePhotoyId, getBanners } from "../../api/api";
import DeleteOffers from "../../components/Dialogs/DeleteOffers";
import { API_URL } from "../../constant";
import withTimedDisplay from "../../utils/timedAlert";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [selectedBannerId, setSelectedBannerId] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true); // For loading state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  }); // Pagination model
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);

  const theme = useTheme();

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);

        setBanners([]);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  const fetchBanners = async () => {
    setLoading(true); // Set loading to true before the API call
    try {
      const response = await getBanners();
      setBanners(response.data);
      setError(null);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false); // Set loading to false after the API call
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedBannerId(id);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedBannerId) {
        await deletePhotoyId(selectedBannerId);
        fetchBanners();
        setOpenDialog(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Box m="0rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header />
        <Link to={`/banner/add`}>
          <Button
            startIcon={<ViewCompactAltOutlinedIcon />}
            sx={{
              mx: 1,
              py: 1,
              background: theme.palette.primary[500],
              color: "white",
            }}
            variant="contained"
          >
            Add Banners
          </Button>
        </Link>
      </Box>
      <div className="mt-4 mb-4">
        {error && showAlert && (
          <TimedAlert
            setShowAlert={setShowAlert}
            sx={{ my: 2 }}
            severity="error"
          >
            {error}
          </TimedAlert>
        )}
        {success && showAlert && (
          <TimedAlert
            setShowAlert={setShowAlert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setSuccess(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity="success"
          >
            {success}
          </TimedAlert>
        )}
      </div>

      <DeleteOffers
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleDeleteConfirm}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
        title="Banner"
        error={error}
      />

      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {banners.length === 0 ? (
            <></>
          ) : (
            <TableContainer>
              <Table sx={{ border: "1px solid #E0E0E0", mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Photo</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>URL</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {banners.map((banner) => (
                    <TableRow key={banner.id}>
                      <TableCell sx={{ width: "200px" }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${banner.url}`}
                        >
                          {" "}
                          <img
                            src={`${API_URL}/${banner.photo.path}`}
                            alt={banner.photo.originalname}
                            style={{ width: "100px", height: "auto" }}
                          />
                        </a>
                      </TableCell>
                      <TableCell>
                        <p>{banner.url}</p>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" gap="4px" justifyContent="start">
                          <Link to={`/banner/edit/${banner.id}`}>
                            <Button
                              sx={{
                                px: 2,
                                background: theme.palette.primary[500],
                                color: "white",
                              }}
                              startIcon={<EditOutlinedIcon />}
                            >
                              Edit
                            </Button>
                          </Link>
                          <Button
                            onClick={() => handleDeleteClick(banner.id)}
                            sx={{
                              px: 2,
                              background: theme.palette.primary[500],
                              color: "white",
                            }}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={banners.length}
                rowsPerPage={paginationModel.pageSize}
                page={paginationModel.page}
                onPageChange={(event, newPage) => {
                  setPaginationModel({ ...paginationModel, page: newPage });
                }}
                onRowsPerPageChange={(event) => {
                  setPaginationModel({ page: 0, pageSize: parseInt(event.target.value, 10) });
                }}
              /> */}
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default Banner;
