import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import {
  AddBoxOutlinedIcon,
  CancelOutlinedIcon,
  LocalOfferOutlinedIcon,
  SummarizeOutlinedIcon,
} from "../../Icons";
import { merchant } from "../../constant";
import { createOffer, getAffiliates, getAllCategory } from "../../api/api";
import withTimedDisplay from "../../utils/timedAlert";

const AddOffers = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const [showAffId, setShowAffId] = useState(true);

  const [formData, setFormData] = useState({
    category: "",
    show_in_hot_deals: 0,
    hot_deals_ranking: 0,
    show_in_new_deals: 0,
    new_deals_ranking: 0,
    merchant: "",
    product_link: "",
    affiliateId: "",
  });

  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);
  const breadcrumbsLinks = [
    {
      id: 1,
      title: "Add Offer",
      href: "/offers/add",
      icon: <LocalOfferOutlinedIcon fontSize="small" />,
    },
  ];

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getAllCategory(false);
      setCategory(response.data.category);
    } catch (err) {
      handleError(err);
    }
  };

  const fetchAffiliates = async () => {
    try {
      const response = await getAffiliates();
      setAffiliate(response.data);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchAffiliates();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevState) => {
      const updatedValue = name === "product_link" ? value.trim() : value;
      if (type === "checkbox") {
        const updatedState = {
          ...prevState,
          [name]: checked ? 1 : 0,
        };
        if (name === "show_in_hot_deals" && checked) {
          updatedState.show_in_new_deals = 0;
        } else if (name === "show_in_new_deals" && checked) {
          updatedState.show_in_hot_deals = 0;
        }
        return updatedState;
      }

      if (name === "merchant") {
        const selectedAffiliate = affiliate?.find((aff) => aff.name === value);
        if (value === "Ajio" || value === "Myntra") {
          setShowAffId(false);
        } else {
          setShowAffId(true);
        }
        return {
          ...prevState,
          merchant: value,
          affiliateId: selectedAffiliate ? selectedAffiliate.affid : "",
        };
      }

      if (name === "hot_deals_ranking" || name === "new_deals_ranking") {
        return {
          ...prevState,
          [name]: value ? Number(value) : "",
        };
      }

      return {
        ...prevState,
        [name]: updatedValue,
      };
    });
  };

  const handleClear = () => {
    setFormData({
      category: "",
      show_in_hot_deals: 0,
      hot_deals_ranking: 0,
      show_in_new_deals: 0,
      new_deals_ranking: 0,
      merchant: "",
      product_link: "",
    });
  };
  const handleInput = (e) => {
    const value = Number(e.target.value);
    if (value < 1) {
      e.target.value = "";
    } else if (value > 10) {
      e.target.value = 10;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (formData.show_in_hot_deals === 0 && formData.show_in_new_deals === 0) {
      setError(
        "At least one deal type must be selected (Hot Deals or New Deals)."
      );
      setShowAlert(true);
      return;
    }

    if (formData.affiliateId) {
      if (formData.merchant === "Amazon") {
        const tag = formData.affiliateId;
        formData.product_link += `&tag=${tag}`;
      } else if (formData.merchant === "Flipkart") {
        formData.product_link += `?affid=${formData.affiliateId}`;
      }
    }
    setLoading(true);

    try {
      await createOffer(formData);
      setSuccess("Offer created successfully!");
      setShowAlert(true);
      handleClear();
    } catch (err) {
      if (err.response && err.response.data) {
        if (
          err.response.data.display_errors &&
          err.response.data.display_errors.length > 0
        ) {
          setError(err.response.data.display_errors[0]);
          setShowAlert(true);
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Box m="0rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header breadcrumbsLinks={breadcrumbsLinks} />
        <Link to={`/offers`}>
          <Button
            startIcon={<SummarizeOutlinedIcon />}
            sx={{
              mx: 1,
              py: 1,
              background: theme.palette.primary[500],
              color: "white",
            }}
            variant="contained"
          >
            List Offer
          </Button>
        </Link>
      </Box>
      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          {error && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="error">
              {error}
            </TimedAlert>
          )}
          {success && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="success">
              {success}
            </TimedAlert>
          )}
        </div>
        <Box>
          <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  label="Product Link"
                  name="product_link"
                  value={formData.product_link}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  helperText="Please enter a valid Affiliated URL of the product."
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Category</InputLabel>
                  <Select
                    sx={{ textAlign: "left" }}
                    name="category"
                    label="Category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    {category &&
                      category.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Merchant</InputLabel>
                  <Select
                    sx={{ textAlign: "left" }}
                    name="merchant"
                    label="Merchant"
                    value={formData.merchant}
                    onChange={handleChange}
                  >
                    {merchant.map((category) => (
                      <MenuItem key={category.id} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {showAffId && (
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    label="Affiliate ID"
                    name="affiliateId"
                    value={formData.affiliateId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item>
              <div className="label-bold">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="show_in_hot_deals"
                      checked={formData.show_in_hot_deals === 1}
                      onChange={handleChange}
                    />
                  }
                  label="Show in Hot Deals"
                />
              </div>
              <div className="label-bold">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="show_in_new_deals"
                      checked={formData.show_in_new_deals === 1}
                      onChange={handleChange}
                    />
                  }
                  label="Show in New Deals"
                />
              </div>
              {formData.show_in_hot_deals === 1 && (
                <Grid item xs={12}>
                  <TextField
                    label="Hot Deals Ranking"
                    name="hot_deals_ranking"
                    type="number"
                    value={formData.hot_deals_ranking}
                    onChange={handleChange}
                    fullWidth
                    required
                    onInput={handleInput}
                    margin="normal"
                    inputProps={{ min: 1, max: 10 }}
                  />
                </Grid>
              )}
              {formData.show_in_new_deals === 1 && (
                <Grid item xs={12}>
                  <TextField
                    label="New Deals Ranking"
                    name="new_deals_ranking"
                    type="number"
                    value={formData.new_deals_ranking}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    onInput={handleInput}
                    inputProps={{ min: 1, max: 10 }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="right">
                <Button
                  startIcon={<AddBoxOutlinedIcon />}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    py: 1,
                    mt: 2,
                    mr: 2,
                    background: theme.palette.primary[500],
                    color: "white",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Add Offer"
                  )}
                </Button>

                <Button
                  onClick={handleClear}
                  startIcon={<CancelOutlinedIcon />}
                  variant="contained"
                  color="primary"
                  sx={{
                    py: 1,
                    mt: 2,
                    background: theme.palette.primary[800],
                    color: "white",
                  }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddOffers;
