/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { API_URL } from "../../constant";
import logo from "../../assets/Images/Logo.png";
import Heading from "../common/Heading/Heading";
import withTimedDisplay from "../../utils/timedAlert";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { email, token } = useParams();

  useEffect(() => {
    if (!email || !token) {
      setError("Invalid password reset link.");
      setShowAlert(true);
    }
  }, [email, token]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setShowAlert(true);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/auth/reset-password`, {
        email,
        token,
        password,
      });
      setSuccess(response.data.message);
      setShowAlert(true);
      setTimeout(() => navigate("/"), 3000);
    } catch (err) {
      if (err.response && err.response.data) {
        if (
          err.response.data.display_errors &&
          err.response.data.display_errors.length > 0
        ) {
          setError(err.response.data.display_errors[0]);
          setShowAlert(true);
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto mb-8 h-12 w-auto"
          src={logo}
          alt="Your Company"
        />
        <Heading title={"Reset Password"} />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleResetPassword}>
          {error && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="error">
              {error}
            </TimedAlert>
          )}
          {success && (
            <TimedAlert setShowAlert={setShowAlert} severity="success">
              {success}
            </TimedAlert>
          )}
          <div>
            <label
              htmlFor="password"
              className="block text-sm text-left font-medium leading-6 text-gray-900"
            >
              New Password
            </label>
            <div>
              <TextField
                id="password"
                // label="New Password"
                name="password"
                type="password"
                placeholder="Enter new password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="confirm-password"
              className="block text-sm text-left font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div>
              <TextField
                id="confirm-password"
                name="confirm-password"
                type="password"
                placeholder="Confirm new password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </div>
          </div>

          <div>
            <div>
              <Button
                disabled={loading}
                type="submit"
                sx={{
                  width: "100%",
                  background: theme.palette.primary[500],
                  color: "white",
                }}
                variant="contained"
              >
                {" "}
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
