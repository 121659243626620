import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Link, useParams } from "react-router-dom";
import {
  AddBoxOutlinedIcon,
  CancelOutlinedIcon,
  CloudUploadOutlinedIcon,
  ContentCutOutlinedIcon,
  DeleteIcon,
  EditOutlinedIcon,
  ViewCompactAltOutlinedIcon,
} from "../../Icons";
import { getBannerById, updateBanner } from "../../api/api";
import { API_URL } from "../../constant";
import withTimedDisplay from "../../utils/timedAlert";

const EditBanner = () => {
  const [photos, setPhotos] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [url, setUrl] = useState("");
  const theme = useTheme();
  const { id } = useParams();
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);

  const breadcrumbsLinks = [
    {
      id: 2,
      title: "Edit Banner",
      href: "/banner/edit",
      icon: <EditOutlinedIcon fontSize="small" />,
    },
  ];
  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  const handleImageChange = (e) => {
    setError(null);
    const file = e.target.files[0];
    if (!file) return;
    const preview = URL.createObjectURL(file); // Generate preview URL for the file
    const img = new Image();
    img.src = preview;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const needsCrop = aspectRatio > 2;

      if (needsCrop) {
        setError(
          "Image aspect ratio is not supported. Please upload a new image or crop it manually."
        );
        setShowAlert(true);
      } else {
        setError(null);
      }
      setPhotos([{ file, needsCrop }]);
      setPreviews([preview]);
    };
  };

  const handleImageCrop = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos[index].needsCrop = false;
    setPhotos(updatedPhotos);
    setError(null);
  };

  useEffect(() => {
    const fetchBannerById = async () => {
      try {
        const response = await getBannerById(id);
        const bannerData = response.data;
        setUrl(bannerData.url);
        setPreviews([`${API_URL}/${bannerData.photo.path}`]);
      } catch (err) {
        handleError(err);
      }
    };

    fetchBannerById();
  }, [id]);

  const handleImageDelete = (index) => {
    const updatedPhotos = photos.filter((_, idx) => idx !== index);
    const updatedPreviews = previews.filter((_, idx) => idx !== index);
    setPhotos(updatedPhotos);
    setPreviews(updatedPreviews);
  };

  const handleClear = () => {
    setPhotos([]);
    setPreviews([]);
    setError(null);
    setSuccess(null);
    setUrl("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    photos.forEach((photo) => {
      formData.append("photo", photo.file);
    });
    formData.append("url", url);

    try {
      await updateBanner(id, formData);
      setSuccess("Banner updated successfully!");
      setError(null);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m="0rem 2.5rem">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header breadcrumbsLinks={breadcrumbsLinks} />
        <Link to={`/banner/`}>
          <Button
            startIcon={<ViewCompactAltOutlinedIcon />}
            sx={{
              mx: 1,
              py: 1,
              background: theme.palette.primary[500],
              color: "white",
            }}
            variant="contained"
          >
            View Banner
          </Button>
        </Link>
      </Box>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-4 mb-4">
            {error && showAlert && (
              <TimedAlert setShowAlert={setShowAlert} severity="error">
                {error}
              </TimedAlert>
            )}
            {success && showAlert && (
              <TimedAlert setShowAlert={setShowAlert} severity="success">
                {success}
              </TimedAlert>
            )}
          </div>
          <Box>
            <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="url"
                  variant="outlined"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    required
                  />
                  <CloudUploadOutlinedIcon />
                </IconButton>
                <span className="text-sm">Upload banner photos (2:1)</span>
                {photos.length > 0 && (
                  <Typography variant="body2" ml={2}>
                    {photos.length} file(s) selected
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {previews.length > 0 && (
                  <Grid container spacing={2}>
                    {previews.map((preview, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <div className="relative">
                          <img
                            src={preview}
                            alt={`Preview ${index + 1}`}
                            style={{ width: "100%", aspectRatio: "2 / 1" }}
                          />
                          {photos[index]?.needsCrop && (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <IconButton
                                sx={{
                                  px: 2,
                                  background: "#2563EB",
                                  "&:hover": {
                                    background: "#2563EB",
                                  },
                                  border: "1px solid white",
                                  borderRadius: "999px",
                                }}
                                onClick={() => handleImageCrop(index)}
                              >
                                <span>
                                  <ContentCutOutlinedIcon
                                    sx={{ fontSize: "18px", color: "white" }}
                                  />
                                </span>
                              </IconButton>
                            </div>
                          )}
                          <div>
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                color: "white",
                                borderRadius: "50%",
                              }}
                              onClick={() => handleImageDelete(index)}
                            >
                              <DeleteIcon fontSize="medium" />
                            </IconButton>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    startIcon={<AddBoxOutlinedIcon />}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      py: 1,
                      mt: 2,
                      mr: 2,
                      background: theme.palette.primary[500],
                      color: "white",
                    }}
                    disabled={loading} // Disable button if aspect ratio is invalid
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Update Banner"
                    )}
                  </Button>

                  <Button
                    onClick={handleClear}
                    startIcon={<CancelOutlinedIcon />}
                    variant="contained"
                    color="primary"
                    sx={{
                      py: 1,
                      mt: 2,
                      background: theme.palette.primary[800],
                      color: "white",
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
    </Box>
  );
};

export default EditBanner;
